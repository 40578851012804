* {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: inherit;
}

button,
select,
input,
textarea {
  padding: 0;
  border: none;
  background-color: unset;
  style: inherit;
}

/* :root {
} */

/*  */
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
}
h4 {
  font-size: 1.3rem;
}

p {
  font-size: 1.2rem;
}

:root {
  --purple: #682ae9;
  --light: #dbdbdb;
  --header-link: #dbdbdb;
  --header-link-hover: #682ae9;
  --header-link-hover-icon: #dbdbdb;
  --header-link-active: #555;
  --std-color: #dbdbdb;
  --bg: #0e1212;
  --bg-rgb: 14, 18, 18;
  --bgLight: radial-gradient(var(--bg), transparent, var(--purple) 130%);
  --gray: grey;
  --comment: #555;
}

/* .animate__animated.animate__fadeInLeft {
  --animate-duration: 1.3s;
} */
